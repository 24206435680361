<template>
  <div :class="classObj"
       class="hl-layout-wrapper">
    <div class="hl-header-wrapper">
      <HlHeader></HlHeader>
    </div>
    <div class="hl-container">
      <Sidebar></Sidebar>
      <div class="indentation">
        <i class="icolor"
           @click="toggleSideBar"
           :class="!isClose?'el-icon-caret-left':'el-icon-caret-right'"></i>
      </div>
      <div class="hl-content">
        <TagsView></TagsView>
        <AppMain></AppMain>
      </div>
    </div>
  </div>
</template>

<script>
import { AppMain, Sidebar, TagsView, HlHeader } from './components'
import { mapState } from 'vuex'
export default {
  name: 'Layout',
  components: {
    AppMain,
    Sidebar,
    TagsView,
    HlHeader
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
    }),
    classObj () {
      return {
        hideSidebar: this.sidebar.opened,
      }
    },
    isClose () {
      return this.sidebar.opened
    },
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    },
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
