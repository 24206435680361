import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/login.json',
    method: 'post',
    data
  })
}

export function logout(data) {
  return request({
    url: '/api/logout.json',
    method: 'post',
    data
  })
}

export function dynamicKey(data) {
  return request({
    url: '/api/dynamicKey.json',
    method: 'get',
    data
  })
}
