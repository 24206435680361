import * as sysUser from '@/api/sysUser'
const actions = {
  saveData({}, parm) {
    return new Promise((resolve, reject) => {
      sysUser.saveData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getDataList({}, parm) {
    return new Promise((resolve, reject) => {
      sysUser.getDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteData({}, parm) {
    return new Promise((resolve, reject) => {
      sysUser.deleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
export default {
  namespaced: true,
  actions
}