<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  width 100%
  height 100%
</style>
// smoothing
// 这个属性可以使页面上的字体抗锯齿
// 使用后字体看起来会更清晰舒服
// 淘宝也用的这个
<script>
export default {
  name: 'App'
}
</script>
