import * as manager from '@/api/manager'
const actions = {
  getDataList({ }, parm) {
    return new Promise((resolve, reject) => {
      manager.getDataList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getVersionList({ }, parm) {
    return new Promise((resolve, reject) => {
      manager.getVersionList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  saveVersion({ }, parm) {
    return new Promise((resolve, reject) => {
      manager.saveVersion(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getPictureList({ }, parm) {
    return new Promise((resolve, reject) => {
      manager.getPictureList(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  deleteData({ }, parm) {
    return new Promise((resolve, reject) => {
      manager.deleteData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  uploadData({ }, parm) {
    return new Promise((resolve, reject) => {
      manager.uploadData(parm).then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

}
export default {
  namespaced: true,
  actions
}