import request from '@/utils/request'


export function getDataList(data) {
  return request({
    url: '/api/clickNum/getDataList.json',
    method: 'post',
    data
  })
}

export function getVersionList(data) {
  return request({
    url: '/api/versionsData/getDataList.json',
    method: 'post',
    data
  })
}

export function saveVersion(data) {
  return request({
    url: '/api/versionsData/saveData.json',
    method: 'post',
    data
  })
}


export function getPictureList(data) {
  return request({
    url: '/api/welcomeData/getDataList.json',
    method: 'post',
    data
  })
}

export function deleteData(data) {
  return request({
    url: '/api/welcomeData/deleteData.json',
    method: 'post',
    data
  })
}
export function uploadData(data,config) {
  return request({
    url: '/api/welcomeData/saveData.json',
    method: 'post',
    data,
    config
  })
}


