import request from '@/utils/request'

export function saveData(data) {
  return request({
    url: '/api/sysUser/saveData.json',
    method: 'post',
    data
  })
}

export function getDataList(data) {
  return request({
    url: '/api/sysUser/getDataList.json',
    method: 'post',
    data
  })
}

export function getUserInfo(data) {
  return request({
    url: '/api/sysUser/getUserInfo.json',
    method: 'post',
    data
  })
}

export function deleteData(data) {
  return request({
    url: '/api/sysUser/deleteData.json',
    method: 'post',
    data
  })
}