import * as user from '@/api/user'
import * as sysUser from '@/api/sysUser'
const state = {
  userid: '',
  roles: [],
  userName: '',
  fullName: '',
  moduleList: [],
  token: '',
  key: '',
}

const mutations = {
  SET_TOKEN: (state, result) => {
    if (result.token) {
      state.token = result.token
      state.userid = result.userid
      localStorage.setItem("systoken", result.token)
    }
  },
  DEL_TOKEN: (state) => {
    state.token = ''
    localStorage.removeItem("systoken")
    state.userid = ""
    state.roles = []
    state.userName == ""
    state.fullName == ""
    state.moduleList == []
  },
  SET_USERINFO: (state, userinfo) => {
    const {
      id,
      roleId,
      userName,
      fullName,
      moduleList
    } = userinfo
    state.userid = id
    state.roles.push(roleId)
    state.userName = userName
    state.fullName = fullName
    state.moduleList = moduleList
  },
  SET_KEY: (state, key) => {
    state.key = key
  }
}

const actions = {
  login({
    commit
  }, userInfo) {
    return new Promise((resolve, reject) => {
      user.login(userInfo).then(response => {
        const token = (response.result && response.result.token) ? response.result.token : '';

        if (token) {
          commit('SET_TOKEN', {
            token: token,
            userid: response.result.id
          })
        }
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getToken({}) {
    return state.token || localStorage.getItem("systoken")
  },

  getUserInfo({
    commit
  }, parm) {
    return new Promise((resolve, reject) => {
      sysUser.getUserInfo(parm).then(response => {
        commit('SET_USERINFO', response.result)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  async logout({
    commit
  }) {
    await user.logout()
    commit('DEL_TOKEN')
  },

  dynamicKey({
    commit
  }) {
    return new Promise((resolve, reject) => {
      user.dynamicKey().then(response => {
        commit('SET_KEY', response.result)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}