import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './permission'
import '@/styles/default.scss'
import comFun from './comfun'
//视频播放插件
import VideoPlayer from 'vue-video-player';
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video

Vue.config.productionTip = false;
Vue.prototype.$comFun = comFun
Vue.use(comFun)
Vue.use(Element)
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");